import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Layout } from 'components';
import { Foundation } from 'containers';

const FoundationPage = () => {
  const data = useStaticQuery(query);

  const introduction = data.allStrapiFoundation.nodes[0].introduction;
  const establishment = data.allStrapiFoundation.nodes[0].establishment;
  const history = data.allStrapiFoundation.nodes[0].history;
  const donation_title = data.allStrapiFoundation.nodes[0].donation_title;
  const donation =
    data.allStrapiFoundation.nodes[0].donation.localFile.publicURL;
  const settlement_title = data.allStrapiFoundation.nodes[0].settlement_title;
  const settlement =
    data.allStrapiFoundation.nodes[0].settlement.localFile.publicURL;
  const office_of_education =
    data.allStrapiFoundation.nodes[0].office_of_education;
  return (
    <Layout>
      <Foundation
        introduction={introduction}
        establishment={establishment}
        history={history}
        donation={donation}
        donation_title={donation_title}
        settlement={settlement}
        settlement_title={settlement_title}
        office_of_education={office_of_education}
      />
    </Layout>
  );
};

const query = graphql`
  query {
    allStrapiFoundation {
      nodes {
        establishment
        introduction
        history
        settlement_title
        settlement {
          localFile {
            publicURL
          }
        }
        donation_title
        donation {
          localFile {
            publicURL
          }
        }
        office_of_education
      }
    }
  }
`;
export default FoundationPage;
